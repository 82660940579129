<template>
  <div>
    <div class="full-width category-container m-t-36">
      <div class="tac container neue neueSettings">
        <Breadcrumbs :path="path" />
      </div>
    </div>
    <section class="full-width" id="hero" v-if="article._embedded['wp:featuredmedia']" :style="'background-image: url(' + article._embedded['wp:featuredmedia']['0'].source_url + ');'" >

      <div class="container" style="display:flex; flex-direction:column;justify-content:space-between;position:relative;z-index:1;">
        <div class="cheseineicieli" style="font-size: 20px !important">
          <div v-if="article.custom_fields.tempo && article.categories.includes(33)"><b>Tempo:</b> {{article.custom_fields.tempo}}</div>
          <div v-if="article.custom_fields.dosi && article.categories.includes(33)"><b>Dosi:</b> {{article.custom_fields.dosi}}</div>
          <div v-if="article.custom_fields.difficolta && article.categories.includes(33)"><b>Difficoltà:</b> {{article.custom_fields.difficolta}}</div>
          <div v-if="article.custom_fields.impatto && article.categories.includes(33)"><b>Impatto:</b> {{article.custom_fields.impatto}}</div>
        </div>

        <div class="heroInfos">
          <div class="col-50 relative">
          </div>
          <div class="col-50 rightAlign">
            <p>
              Condividi l'articolo
              &nbsp;
              <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + location" target="_BLANK" class="socialRecipe"><span class="fa fa-facebook-f" aria-hidden="true"></span></a>
              &nbsp;
              <a :href="'whatsapp://send?text=' + location" target="_BLANK" class="socialRecipe"><span class="fa fa-whatsapp" aria-hidden="true"></span></a>
            </p>

          </div>
        </div>
      </div>
    </section>
    <section class="full-width" id="recipeDescr">
      <div class="container" style="flex-direction:column;">
        <center>
          <h1 class="articleTitle" v-html="article.title.rendered"></h1>
        </center>
        <br><br>
      </div>
    </section>
    <section class="full-width fusion-body padrenostro" id="recipeDescr">
      <div class="container" style="flex-direction:column;">
        <div v-html="articleContent" />
      </div>
    </section>

    <!--<section class="full-width" id="aboutAuthor">
      <div class="container">
        <div class="row" style="align-items:center;justify-content:flex-start;">
          <h3>Autore dell'articolo: </h3>
          <img src="@/assets/placeholder-image.png" style="margin-left:1rem;width:45px;height:45px;border-radius:50%;" alt="">
          <p style="max-width:60%;font-size:12px;margin-left:1rem;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia consequuntur facere ratione sapiente eos illo at amet labore architecto repudiandae totam numquam, atque rerum, quo ea ducimus dignissimos dolore. Cumque?</p>
        </div>
      </div>
    </section>-->
    <section class="full-width">
      <div class="container" style="margin-bottom:2rem;">
        <h3 class="recipeIngredients"><b>{{article.categories.includes(33) ? 'Per questa ricetta consigliamo' : 'Ultimi articoli'}}</b></h3>
      </div>
    </section>
    <div class="full-width gray-bg">
      <div class="container" style="display: block;padding: 30px 0" v-if="article && article.categories.includes(33)">
        <ProductSlider :related="related" code="EC61000" :maxProducts="4"/>
      </div>
      <div class="container" style="display: block;padding: 30px 0" v-if="article && !article.categories.includes(33)">
        <ArticleSlider />
      </div>
    </div>
  </div>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs'
    import ProductSlider from '@/components/ProductSlider'
    import ArticleSlider from '@/components/ArticleSlider'
    import axios from 'axios'
    import Vue from 'vue'

    export default {
      name : 'Recipe',
      components : {
        Breadcrumbs,
        ProductSlider,
        ArticleSlider
      },
      data(){
        return {
          url: require('@/assets/caffemanifesto.jpg'),
          urlVideo: require('@/assets/ricette1.jpg'),
          article : {},
          articleContent : '',
          path: [
            { label: 'Home', path: '/' },
            { label: 'Blog', path: '/blog' },
          ],
          settings: {
        "dots": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 4,
        "swipeToSlide": false,
        "touchThreshold": 2,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      },
      location: window.location.href.replace('localhost:8080', 'shop.altromercato.it').replace('http:/', 'https:/'),
      related: []
      }
    },

    watch: {
      '$route.params.id': function () {
        this.initPage()
        Vue.$forceUpdate()
      }
    },

    async created(){
      await this.initPage()
    },

    methods: {
      async initPage () {
        await axios.get(`https://altromercato.it/wp-json/wp/v2/posts/${this.$route.params.id}/?_embed`)
          .then((res) => {
            this.article = res.data
            this.articleContent = this.article.content.rendered
            window.wordpressVideoLoad = function(el) {
              el.closest(".elegant-advanced-video").outerHTML = `<div class="wordpressVideoWrapper"><iframe allowfullscreen="true" src="${el.dataset.embedUrl}"></iframe></div>`
            }
            this.articleContent = this.articleContent.replace(/\sdata-embed-url/g, ` onclick="window.wordpressVideoLoad(this)" data-embed-url`)

            if (this.article.custom_fields && this.article.custom_fields.prodotti) {
              this.related = this.article.custom_fields.prodotti.split(',')/*.map((item) => {
                return this.$utils.getProduct(item)
              })*/
            }

            this.path = this.path.slice(0,2)
            this.path.push({
              label: this.article.title.rendered,
              path: '/'
            })
          })
      }
    }
  }
</script>
<style>
  .container{
    width: 100%!important;
  }

  .fusion-text p, .padrenostro li {
    font-family: 'Caecilia';
  }

  .padrenostro h1,
  .padrenostro h1 b,
  .padrenostro h2,
  .padrenostro h2 b,
  .padrenostro h3,
  .padrenostro h3 b {
    font-family: 'neue-haas-grotesk-display' !important;
  }

  .padrenostro a {
    font-family: inherit !important;
    font-size: inherit !important;
  }

  .padrenostro h2,
  .padrenostro h2 b {
    font-size: 32px !important;
  }

  .padrenostro .fusion-button {
    display: inline-block !important;
    cursor: pointer !important;
    height: 46px !important;
    line-height: 46px !important;
    border-radius: 23px !important;
    padding: 0 58px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    font-family: neue-haas-grotesk-display, sans-serif !important;
    color: #fff !important;
    background-color: #000 !important;
    border: 0 !important;
  }

  @media (max-width: 768px) {
    .padrenostro .fusion-title.fusion-sep-none .title-heading-left {
      font-size: 22px !important;
    }
  }

</style>
<style scoped lang="scss">
  @media (max-width: 768px){
    #hero .heroInfos {
      height: auto !important;
    }
    .rightAlign{
      text-align: right;
      max-width: 100% !important;
      width: 100% !important;
    }
    .container{
      padding: 0 20px;
    }
    #hero{
      height: 500px!important;
    }
    #hero .container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      height: 100%;
    }
    #hero .heroInfos{
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 180px;
    }
    .col-50{
      max-width: 100%!important;
    }
    .convideo .container{
      align-items: center;
    }
    .convideo .container span{
      width: fit-content;
    }
    #aboutAuthor{
      height: 300px;
    }
    #aboutAuthor .container, #aboutAuthor .row{
      height: 100%;
      flex-direction: column;
      align-items: flex-start!important;
      justify-content: space-between!important;
    }
    #abourAuthor .row img{
      margin-left: 0!important;
    }
    #aboutAuthor .row p{
      margin-left: 0!important;
    }

    .padrenostro .title-heading-left strong {
      font-size: 22px !important;
    }
  }
  @media (max-width: 1024px){
    .container{
      padding: 0 20px;
    }
    #hero{
      height: 500px!important;
    }
    #hero .container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      height: 100%;
    }
    #hero .heroInfos{
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 180px;
    }
    .col-50{
      max-width: 100%!important;
    }
    .convideo .container{
      align-items: center;
    }
    .convideo .container span{
      width: fit-content;
    }
    #aboutAuthor{
      height: 300px;
    }
    #aboutAuthor .container, #aboutAuthor .row{
      height: 100%;
      flex-direction: column;
      align-items: flex-start!important;
      justify-content: space-between!important;
    }
    #abourAuthor .row img{
      margin-left: 0!important;
    }
    #aboutAuthor .row p{
      margin-left: 0!important;
    }
  }
  .container{
    width: 100%!important;
  }
  ul{
    margin: 10px -20px;
  }
  ul li{
    font-weight: lighter;
    font-size: 15px;
  }
  h1,h2,h3,h4,h5,h6,p{
    margin: 0;
  }
  h1,h2,h3,h4,h5,h6{
    font-family: neue-haas-grotesk-display, sans-serif;
    letter-spacing: 0.4px;
  }
  a.socialRecipe {
    color: white;
    max-width: 32px;
    max-height: 32px;
    padding: 6px 10px;
    border-radius: 50%;
    background: black;
    cursor: pointer;
  }
  .heroInfos{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col-50{
    max-width: 50%;
  }
  .col-48{
    max-width: 48%;
    width: 48%;
  }
  .rightAlign{
    text-align: right;
  }
  #hero{
    height: 400px;
    padding: 60px 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
  #hero::after{
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:white;
    opacity: 0.55;
  }
  #hero .articleTitle{
    position: relative;
    z-index: 1;
    font-size: 25px;
  }
  #recipeDescr h3{
    margin-bottom: 1rem;
    font-weight: 900;
  }

  .articleTitle {
    margin-top: 40px;
    font-size: 46px;
  }

  video {
    max-width: 100% !important;
  }

  #video .colvideo{
    margin: 0!important;
    max-width: 100vw!important;
    width: 100vw!important;
  }
  #video .fusion-column-wrapper{
    margin: 0!important;
    max-width: 100vw!important;
    width: 100vw!important;
  }
  #video .fusion-flex-align-items-flex-start{
    width: unset!important;
    max-width: unset!important;
    margin: 0!important;
  }

  #video .colvideo .fusion-video{
    max-width: 100vw!important;
    max-height: 500px!important;
  }
  #video .colvideo .fusion-video iframe{
    max-width: 100vw!important;
    width: 100vw;
    border: 0;
    max-height: 500px!important;
  }
  .convideo{
    height: unset;
    margin: 60px 0;
    padding: 60px 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .conimmagini{
    display: flex;
    justify-content: space-between;
  }
  .convideo::after{
    /*content: '';*/
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:black;
    opacity: 0.75;
  }
  .convideo .container {
    justify-content: center;
  }
  .convideo .container span{
    position: relative;
    z-index: 1;
    color: white;
    font-size: 98px;
  }
  #recipeFull{
    padding: 60px 0;
  }
  #recipeFull h3{
    margin-bottom: 1rem;
    font-weight: 900;
  }
  #aboutAuthor{
    padding: 0rem 0 4rem 0;
  }

  .padrenostro {
    font-family: neue-haas-grotesk-display, sans-serif !important;
    line-height: 160% !important;
    letter-spacing: 0.2px;
  }

  .cheseineicieli {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }

  .relative {
    position: relative;
  }

  .cheseineicieli div {
    margin-top: 15px;
  }
</style>

<style lang="scss" src="@/assets/fusion.scss"></style>
