<template>
<div>
  <div class="full-width gray-spacer"></div>
  <div class="full-width gray-bg">
    <div class="container" style="display: block;">

      <VueSlickCarousel v-bind="settings" :arrows="true" v-if="products.length">
        <ArticleThumb
          v-for="(item, k) in products"
          v-bind:key="k"
          :postId="item.id"
          :post="item" />
      </VueSlickCarousel>

    </div>
  </div>

  <div class="full-width gray-spacer"></div>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ArticleThumb from '@/components/ArticleThumb.vue'
import axios from 'axios'
import paginaZuccheriPostdata from '@/mockups/paginaZuccheriPostdata.js'

export default {
  name: 'ArticleSlider',
  data: function () {
    return {
      products: [],
      settings: {
        "dots": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 4,
        "swipeToSlide": false,
        "touchThreshold": 2,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      }
    }
  },

  components: {
    VueSlickCarousel,
    ArticleThumb
  },

  props: ['code', 'exclude', 'supplier', 'related', 'order'],

  async created () {
    let resp = await axios.get('https://altromercato.it/wp-json/wp/v2/posts?_embed&per_page=100&categories=32,33')
    this.products = [paginaZuccheriPostdata, ...resp.data]
  }
}
</script>

<style scoped>
.product-outer {
  padding: 0 5px;
}

.product-card {
  width: 285px;
  min-height: 442px;
  padding: 20px 0;
  background-color: #fff;
  position: relative;
}

.product-card .promo {
  position: absolute;
  top: 16px;
  right: 0;
  padding: 0 6px;
  height: 22px;
  line-height: 24px;
  color: #fff;
  background-color: #f09049;
  font-weight: 800;
  font-size: 14px;
}

.product-image {
  width: 235px;
  height: 235px;
  object-fit: cover;
  display: block;
  margin: 0 auto 48px auto;
}

.marks {
  position: absolute;
  top: 16px;
  left: 10px;
  width: 32px;
}

.marks img {
  width: 32px;
  height: auto;
  margin-bottom: 3px;
}

.product-content {
  padding: 0 22px;
}

.product-content h2 {
  font-size: 17px;
  line-height: 130%;
  font-weight: 700;
  font-family: neue-haas-grotesk-display, sans-serif;
  margin: 10px 0 0 0;
  min-height: 66px;
}

.product-card .discount {
  position: relative;
  left: -10px;
  padding: 0 6px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  background-color: #f09049;
  /*background-color: #fff;*/
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  font-family: neue-haas-grotesk-display, sans-serif;
  margin: 5px 0;
}

.product-card .discount .old-price {
  text-decoration: line-through;
  display: inline-block;
  margin-right: 6px;
}

.product-card .price .current {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  line-height: 100%;
}

.product-card .price .online-price {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  letter-spacing: 0.4px;
}

.hover-img .back {
  display: none;
}

.hover-img:hover .back {
  display: block;
}

.hover-img:hover .front {
  display: none;
}

@media (max-width: 680px) {
  .product-card {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>

